






































import Vue from 'vue';
import RideeUser from '@/dtos/user';
import FollowButton from '@/components/Mobile/FollowButton.vue';
export default Vue.extend({
  components: { FollowButton },
  props: {
    user: {
      type: RideeUser,
      required: true,
    },
  },
});
