











































import Vue from 'vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
  props: {
    userId: {
      type: String,
      required: true,
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      followingUser: 'user/isFollowingUser',
    }),
    isFollowingUser(): boolean {
      return this.followingUser(this.userId);
    },
  },
  methods: {
    followUser(): void {
      this.$store.dispatch('user/followUser', this.userId);
    },
    disfollowUser(): void {
      this.$store.dispatch('user/removeFollow', this.userId);
    },
  },
});
